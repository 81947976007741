// ====================================================================================
// font-style: normal;
// ====================================================================================
@font-face {
  font-family: 'SharpSans';
  src:  url('fonts/SharpSans/SharpSans-Thin.woff2') format('woff2'),
        url('fonts/SharpSans/SharpSans-Thin.woff') format('woff');
  font-weight: 100;
}

@font-face {
  font-family: 'SharpSans';
  src:  url('fonts/SharpSans/SharpSans-Light.woff2') format('woff2'),
        url('fonts/SharpSans/SharpSans-Light.woff') format('woff');
  font-weight: 200;
}

@font-face {
  font-family: 'SharpSans';
  src:  url('fonts/SharpSans/SharpSans-Book.woff2') format('woff2'),
        url('fonts/SharpSans/SharpSans-Book.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'SharpSans';
  src:  url('fonts/SharpSans/SharpSans-Medium.woff2') format('woff2'),
        url('fonts/SharpSans/SharpSans-Medium.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'SharpSans';
  src:  url('fonts/SharpSans/SharpSans-SemiBold.woff2') format('woff2'),
        url('fonts/SharpSans/SharpSans-SemiBold.woff') format('woff');
  font-weight: 600;
}

@font-face {
  font-family: 'SharpSans';
  src:  url('fonts/SharpSans/SharpSans-Bold.woff2') format('woff2'),
        url('fonts/SharpSans/SharpSans-Bold.woff') format('woff');
  font-weight: 700;
}

@font-face {
  font-family: 'SharpSans';
  src:  url('fonts/SharpSans/SharpSans-ExtraBold.woff2') format('woff2'),
        url('fonts/SharpSans/SharpSans-ExtraBold.woff') format('woff');
  font-weight: 900;
}


// ====================================================================================
// font-style: italic;
// ====================================================================================
@font-face {
  font-family: 'SharpSans';
  src:  url('fonts/SharpSans/SharpSans-ThinItalic.woff2') format('woff2'),
        url('fonts/SharpSans/SharpSans-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'SharpSans';
  src:  url('fonts/SharpSans/SharpSans-LightItalic.woff2') format('woff2'),
        url('fonts/SharpSans/SharpSans-LightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'SharpSans';
  src:  url('fonts/SharpSans/SharpSans-MediumItalic.woff2') format('woff2'),
        url('fonts/SharpSans/SharpSans-MediumItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'SharpSans';
  src:  url('fonts/SharpSans/SharpSans-BookItalic.woff2') format('woff2'),
        url('fonts/SharpSans/SharpSans-BookItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'SharpSans';
  src:  url('fonts/SharpSans/SharpSans-SemiBoldItalic.woff2') format('woff2'),
        url('fonts/SharpSans/SharpSans-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'SharpSans';
  src:  url('fonts/SharpSans/SharpSans-BoldItalic.woff2') format('woff2'),
        url('fonts/SharpSans/SharpSans-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'SharpSans';
  src:  url('fonts/SharpSans/SharpSans-ExtraBoldItalic.woff2') format('woff2'),
        url('fonts/SharpSans/SharpSans-ExtraBoldItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}
